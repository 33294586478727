import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../components/layout/Layout"
import Seo from "../../components/seo"
import TeamPics from "../../components/TeamPics.js"

const Doctors = styled.div`
  background: ${props => props.theme.bgLight};
  padding: 86px 16px;

  @media (min-width: 1920px) {
    padding: 172px 48px;
    display: flex;
    justify-content: space-between;

    > div:last-of-type {
      margin-top: 0;
    }
  }
`

const Box = styled.div`
  text-align: center;

  :last-of-type {
    margin-top: 64px;
  }

  .gatsby-image-wrapper {
    width: 250px;
    height: 250px;
    max-width: 100%;
  }

  h2,
  h3 {
    margin: 0;
  }

  h2 {
    font-size: 1.5rem;
    text-transform: none;
  }

  h3 {
    font-size: 1.125rem;
  }

  > div:last-of-type {
    margin: 24px 0 0;
  }

  @media (min-width: 600px) {
    .gatsby-image-wrapper {
      width: 400px;
      height: 400px;
    }

    :last-of-type {
      margin-top: 86px;
    }

    h2 {
      font-size: 2.25rem;
      line-height: 190%;
    }
    h3 {
      font-size: 1.5rem;
      line-height: 161%;
    }
  }

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;

    > div:last-of-type {
      margin-left: 32px;
    }
  }
`

export default function OurTeam() {
  return (
    <Layout learnMore>
      <Seo
        title="Meet The Staff At Pikes Peaks Oral Surgery Center in Colorado Springs, CO"
        description="Our qualified and compassionate team is happy to assist you throughout your entire treatment. L more about our team at Pikes Peak!"
      />

      <section className="container" style={{ margin: "128px auto" }}>
        <StaticImage
          src="../../images/team/team.jpg"
          width={816}
          height={459}
          quality={100}
          alt="team"
          placeholder="blurred"
          css={css`
            border-radius: 16px;
            overflow: hidden;
            filter: drop-shadow(0px 16px 30px rgba(140, 124, 101, 0.3));
            margin: 0 auto 64px;
            max-width: 816px;
            display: block;
          `}
        />

        <h2>OUR TEAM</h2>

        <p>
          Pikes Peak Oral Surgery & Dental Implant Center is proud to maintain a
          front office and surgical team that is dedicated to providing patients
          with the most comfortable and pleasant surgical treatment possible.
          Ensuring that our patients receive optimal outcomes and a positive
          experience is our top priority.
        </p>
      </section>
      <Doctors>
        <Box>
          <StaticImage
            src="../../images/team/michael.jpg"
            width={400}
            height={400}
            quality={100}
            placeholder="blurred"
            alt="dr michael kunkel"
            css={css`
              border-radius: 50%;
              overflow: hidden;
            `}
          />
          <div>
            <h2>Dr. Michael Kunkel, DDS</h2>
            <h3>Oral & Maxillofaical Surgery</h3>
          </div>
        </Box>

        <Box>
          <StaticImage
            src="../../images/team/trent.jpg"
            width={400}
            height={400}
            quality={100}
            placeholder="blurred"
            alt="dr trent listello"
            css={css`
              border-radius: 50%;
              overflow: hidden;
            `}
          />
          <div>
            <h2>Dr. Trent W. Listello</h2>
            <h3>Oral & Maxillofaical Surgery</h3>
          </div>
        </Box>
      </Doctors>
      <TeamPics />
    </Layout>
  )
}
